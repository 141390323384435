export default {
  methods: {

    roundNumber (number, roundOf = 1000) {
      const toFixedByRoundOf = Math.round(number * roundOf) / roundOf
      const toFixedTwo = Math.round(toFixedByRoundOf * 100) / 100

      return Number(toFixedTwo).toFixed(2)
    },

    taxAmount (grossPrice, businessTax, VAT = 12, transactionType) {
      if (['Vatable'].includes(businessTax) && ['With-2307'].includes(transactionType)) {
        const originalTaxAmount = Number(grossPrice) - (Number(grossPrice) * (100 / (100 + Number(VAT))))
        const roundedTaxAmount = this.roundNumber(originalTaxAmount)

        // console.log('TAX AMOUNT', originalTaxAmount, roundedTaxAmount)

        return roundedTaxAmount
      }

      return Number(0.00).toFixed(2)
    },

    taxableAmount (grossPrice, taxAmount, businessTax, transactionType) { // netPrice
      if (['Vatable'].includes(businessTax) && ['With-2307'].includes(transactionType)) {
        const originalTaxableAmount = Number(grossPrice) - Number(taxAmount)
        const roundedTaxableAmount = this.roundNumber(originalTaxableAmount)

        // console.log('TAXABLE AMOUNT', originalTaxableAmount, roundedTaxableAmount)

        return roundedTaxableAmount
      }

      // return Number(['With-2307'].includes(transactionType) ? grossPrice : 0.00).toFixed(2)
      return this.roundNumber(grossPrice)
    },

    withHoldingTax (taxableAmount, percentage, taxStatus, transactionType) {
      if (['Liable'].includes(taxStatus) && ['With-2307'].includes(transactionType)) {
        const originalWithHoldingTax = Number(taxableAmount) * (Number(percentage) / 100)
        const roundedWithHoldingTax = this.roundNumber(originalWithHoldingTax)

        // console.log('WITH HOLDING TAX', originalWithHoldingTax, roundedWithHoldingTax)

        return roundedWithHoldingTax
      }

      return Number(0.00).toFixed(2)
    },

    amountDue (grossPrice, withHoldingTax) {
      const originalAmountDue = Number(grossPrice) - Number(withHoldingTax)
      const roundedAmountDue = this.roundNumber(originalAmountDue)

      // console.log('AMOUNT DUE', originalAmountDue, roundedAmountDue)

      return roundedAmountDue
    },

    vatCalculator (grossPrice, businessTax, taxStatus, percentage, transactionType = 'Without-2307') {
      const taxAmount = this.taxAmount(grossPrice || 0.00, businessTax, 12, transactionType)
      const taxableAmount = this.taxableAmount(grossPrice || 0.00, taxAmount, businessTax, transactionType)
      const withHoldingTax = this.withHoldingTax(taxableAmount, percentage, taxStatus, transactionType)
      const amountDue = this.amountDue(grossPrice || 0.00, withHoldingTax, transactionType)

      return {
        taxAmount, taxableAmount, withHoldingTax, amountDue
      }
    }
  }
}
